<template>
  <div class="brand-list-container">
    <DsnPanel>
      <div slot="header" class="title clearfix">
        <el-form
            :inline="true"
            :model="queryParam"
            ref="testQueryForm"
            :rules="{}"
            @submit.native.prevent
        >
          <el-form-item :label="`${this.$t('brands.fields.brandName')}:`" prop="brandName" size="medium">
            <dsn-input v-model="queryParam.brandName" size="medium"
                       :placeholder="`${this.$t('commmsg.pleaseInput')+' '+this.$t('brands.fields.brandName')}`"></dsn-input>
          </el-form-item>
          <el-form-item :label="`${this.$t('brands.fields.brandFullName')}:`" prop="brandFullName" size="medium">
            <dsn-input v-model="queryParam.realname" size="medium"
                       :placeholder="`${this.$t('commmsg.pleaseInput')+' '+this.$t('brands.fields.brandFullName')}`"></dsn-input>
          </el-form-item>
          <el-form-item>
            <dsn-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleQuery(['testQueryForm'])"
            >{{ $t('commbtn.query') }}
            </dsn-button>
            <dsn-button
                size="medium"
                type
                plain
                icon="el-icon-refresh"
                class="reset-button"
                @click="handleReset('materilList')"
            >{{ $t('commbtn.reset') }}
            </dsn-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="operation">
        <dsn-button
            size="medium"
            type="success"
            icon="el-icon-folder-add"
            @click.native="handleAdd"
        >{{ this.$t('commbtn.add') }}
        </dsn-button>
        <dsn-button
            size="medium"
            type="primary"
            icon="el-icon-edit"
            :disabled="selectionList.length !== 1"
            @click="handleEdit"
            style="margin-left:0"
        >{{ this.$t('commbtn.edit') }}
        </dsn-button>
        <dsn-button
            size="medium"
            type="danger"
            icon="el-icon-delete"
            style="margin-left:0"
            :disabled="selectionList.length <= 0"
            @click="handleDeleteCheck"
        >{{ this.$t('commbtn.delete') }}
        </dsn-button>
      </div>

      <dsn-table
          @row-click="selectCheckBox"
          ref="multipleTable"
          :data="pagingConfig.tableData"
          v-loading="pagingConfig.tableLoading"
          tooltip-effect="dark"
          style="width: 100%;"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"/>
        <!--        <el-table-column type="index" label="序号"/>-->
        <el-table-column prop="brandName" :label="this.$t('brands.fields.brandName')" align="center" width="250"/>
        <el-table-column prop="brandFullName" :label="this.$t('brands.fields.brandFullName')" align="center"
                         width="250"/>
        <el-table-column prop="remark" :label="this.$t('brands.fields.remark')" align="center" width="300"/>
        <el-table-column :label="''" align="center"/>
      </dsn-table>
      <DsnFooter>
        <dsn-pagination
            v-if="showPage"
            background
            layout="->,total,prev,pager,next,sizes"
            :total="pagingConfig.total"
            :page-size="pagingConfig.pageSize"
            :page-sizes="[5, 10, 15, 20]"
            :current-page="pagingConfig.current"
            @size-change="handlePageSizeChange"
            @current-change="handleCurrentChange"
        ></dsn-pagination>
      </DsnFooter>
    </DsnPanel>
    <el-dialog title="删除" :visible.sync="deleteDialog" :width="defaltDialogWidth" v-el-drag-dialog>
      <span>是否确认删除{{ selectionList.length }}条数据？</span>
      <span slot="footer" class="dialog-footer">
        <dsn-button @click="deleteDialog = false">{{ this.$t('commbtn.cancel') }}</dsn-button>
        <dsn-button type="primary" @click="handleDelete">{{ this.$t('commbtn.confirm') }}</dsn-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {mapMutations} from "vuex";
import DsnFooter from "@/views/layout/dsn-footer";
import {deleteUserById} from "@/api/efms/users/users.api";
import {getBrandList} from "@/api/efms/equipments/equipment.brand.api";

export default {
  name: 'brandList',
  components: {DsnFooter},
  inject: ["defaltDialogWidth"],
  data() {
    return {
      listData: [],
      queryParam: {
        brandName: null,
        brandFullName: null
      },
      showPage: true,
      pagingConfig: {
        current: 1,
        pageSize: 10,
        count: 0,
        tableLoading: false,
        total: 0,
        tableData: [],
      },
      deleteDialog: false,
      selectionList: [],
    }
  },
  computed: {},
  created() {
    this.initData();
  },
  activated() {
    this.initData();
  },
  methods: {
    ...mapMutations(["BRANDLIST"]),
    sexFormatter(data) {
      return data === 1 ? this.$t('users.fields.male') : this.$t('users.fields.female')
    },
    handleQuery(formNames) {
      this.$refs[formNames].validate(valid => {
        if (valid) {
          this.getSysUserList();
        } else {
          return false;
        }
      });
    },
    handleReset(formName) {
      this.$refs[formName].resetFields();
      this.handleQuery();
    },
    handlePageSizeChange(pageSize) {
      this.pagingConfig.pageSize = pageSize;
      this.pagingConfig.current = 1;
      this.initMainList();
    },
    handleCurrentChange(currentChange) {
      console.info(currentChange)
      this.pagingConfig.current = currentChange;
      this.initMainList(true);
    },
    handleSelectionChange(val) {
      this.selectionList = val;
    },
    selectCheckBox(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    handleEdit() {
      const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      this.BRANDLIST(tempArr);
      localStorage.setItem("brandList", JSON.stringify(this.selectionList));
      this.$router.push({
        name: "brandEdit",
        query: {operateType: "edit"}
      });
    },
    handleAdd() {
      // const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      this.BRANDLIST([]);
      // localStorage.setItem("positionRefList", JSON.stringify(this.selectionList));
      this.$router.push({
        name: "brandAdd",
        query: {operateType: "add"}
      });
    },
    handleDelete() {
      const {selectionList} = this;
      const params = selectionList.map(item => ({
        id: item.id,
      }));

      deleteUserById(params).then(result => {

      })
      console.info(params)
    },
    handleDeleteCheck() {
      this.messageBox()
          .then(() => {
            this.handleDelete();
          })
          .catch(() => {
            this.notification().warn({
              body: "已经取消删除"
            });
          });
    },
    initData() {
      this.initMainList();
    },
    initFakeData() {
      for (let i = 0; i <= 100; i++) {
        this.listData.push({
          id: i,
          username: 'acc' + i,
          realname: 'acc' + i,
          deptId: +i,
          deptLeaderId: '部门' + i,
          deptLeader: "部门负责人" + i,
          activeStatus: 1,
          position: '车间' + i,
          activeStatusDesc: i === 1 ? 'active' : 'inactive'
        })
      }
    },
    initMainList(showPage) {
      if (!showPage) {
        this.pagingConfig.current = 1;
      }
      const data = {
        ...this.queryParam,
        page: this.pagingConfig.current,
        pageSize: this.pagingConfig.pageSize
      };
      this.showPage = showPage;
      this.pagingConfig.tableLoading = true
      getBrandList(data).then((response) => {
        const {data, status} = response;
        this.pagingConfig.tableLoading = false
        if (status === 200) {
          this.showPage = true;
          this.pagingConfig.total = data.totalCount;
          this.pagingConfig.tableData = [...data.data]
        } else {
          this.notification().warn({
            body: data.errMsg
          });
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.brand-list-container {
  .operation {
    width: 100%;
    height: 48px;

    .dsn-button {
      margin: 0 8px 0 0;
    }
  }
}

//::v-deep.el-table .cell {
//  font-size: 18px;
//}

//::v-deep .el-form-item__label {
//  font-size: 25px;
//}
//
//::v-deep .el-form-item__content {
//  font-size: 25px;
//}
</style>
