<template>
  <div class="brand-add-container">
    <div class="operate">
      <dsn-button
          size="small"
          icon="el-icon-back"
          type="primary"
          @click.native="handleBack"
      >{{ this.$t('commbtn.back') }}
      </dsn-button>
      <!--      <dsn-button-save-->
      <!--          @click.native="checkAdd('materialGroupForm')"-->
      <!--          v-if="hasUpdateBtnAuth"-->
      <!--      >保存-->
      <dsn-button-save
          @click.native="checkAdd(['brandFormRef'])">{{ this.$t('commbtn.save') }}
      </dsn-button-save
      >
      <dsn-button
          size="small"
          type
          plain
          icon="el-icon-refresh"
          class="reset-button"
          @click.native="handleReset(['brandFormRef'])"
      >{{ this.$t('commbtn.reset') }}
      </dsn-button>
    </div>
    <div class="info">
      <el-form
          :model="brandForm"
          ref="brandFormRef"
          :inline="true"
          :rules="brandFormRules"
          class="userFormTop"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item :label="this.$t('brands.fields.brandName')" prop="brandName" :label-width="labelWidth">
              <dsn-input
                  v-model.trim="brandForm.brandName"
                  placeholder="brand name"
                  :disabled="isEditStation"
              ></dsn-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item :label="this.$t('brands.fields.brandFullName')" prop="brandFullName"
                          :label-width="labelWidth">
              <dsn-input
                  v-model.trim="brandForm.brandFullName"
                  placeholder="fullname"
              ></dsn-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item :label="this.$t('brands.fields.remark')" prop="remark" :label-width="labelWidth">
              <dsn-input type="text"
                         v-model.trim="brandForm.remark"
                         placeholder="remark"
              ></dsn-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
    </div>
  </div>

</template>
<script>

import {addBrand} from "@/api/efms/equipments/equipment.brand.api";

export default {
  name: 'brandAdd',
  data() {
    return {
      isEditStation: false, //是否可编辑站位
      brandForm: {},
      labelWidth: "100px",
      brandFormRules: {
        brandName: [{required: true, message: "请输入name", trigger: "change"}],
        brandFullName: [{required: false, message: "请输入fullname", trigger: "change"}],
        remark: [{required: false, message: "请输入remark", trigger: "change"}],
      },
    }
  },
  created() {
    this.operateType = this.$route.query.operateType;
  },
  methods: {
    handleBack() {
      this.$router.pushAndClear(
          {name: "brandList"},
          {
            deleteItem: this.$route,
            current: this.$route,
          }
      );
    },
    checkAdd(formName) {
      let count = 0;
      formName.forEach((element) => {
        this.$refs[element].validate((valid) => {
          if (valid) {
            count++;
          } else {
            return false;
          }
        });
      });
      if (count >= 1) {
        this.messageBox({
          type: "success",
          title: `${this.$t('commbtn.save')}`,
          content: `${this.$t('commmsg.confirmToSave')}?`,
        })
            .then(() => {
              this.handleDataSave();
            })
            .catch(() => {
              this.notification().warn({
                body: `${this.$t('commmsg.cancelSave')}`,
              });
            });
      }
    },
    handleDataSave() {
      const postData = {};
      postData['brandName'] = this.brandForm.brandName
      postData['brandFullName'] = this.brandForm.brandFullName
      postData['remark'] = this.brandForm.remark
      addBrand(postData).then(result => {
        const {code, errMsg} = result.data;
        const {status} = result;
        if (status === 200) {
          this.notification().success({
            body: this.$t('commmsg.addSuccess'),
          });
          this.handleBack();
          return;
        } else {
          this.notification().error({
            body: errMsg,
          });
        }
      }).catch(err => {
        this.notification().error({
          body: this.$t('errors.fields.serverInternalError'),
        });
      })
    },
    handleReset(formName) {
      if (this.operateType === "add") {
        formName.forEach((element) => {
          this.$refs[element].resetFields();
        });
      }
    }
  }
}
</script>
<style lang="scss">

.brand-add-container {
  .operate {
    padding: 16px;

    .el-button {
      margin: 0 8px 0 0;
    }
  }

  .info {
    background-color: #ffffff;
    padding: 16px;

    .userFormTop {
      .el-form-item {
        margin: 0 24px 16px 0;
      }
    }

    .userForm {
      .el-form-item {
        margin: 0 24px 16px 0;

        .el-select {
          width: 320px;
        }

        .el-input {
          width: 320px;
        }
      }
    }
  }
}
</style>
